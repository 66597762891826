import { file, questionCircle, saveAs } from "../components/icons";
import { ProjectName } from "../components/ProjectName";
import { ToolButton } from "../components/ToolButton";
import { Tooltip } from "../components/Tooltip";
import { DarkModeToggle } from "../components/DarkModeToggle";
import { loadFromJSON, saveAsJSON } from "../data";
import { resaveAsImageWithScene } from "../data/resave";
import { t } from "../i18n";
import { useDevice } from "../components/App";
import { KEYS } from "../keys";
import { register } from "./register";
import { CheckboxItem } from "../components/CheckboxItem";
import { getExportSize } from "../scene/export";
import { DEFAULT_EXPORT_PADDING, EXPORT_SCALES, THEME } from "../constants";
import { getSelectedElements, isSomeElementSelected } from "../scene";
import { getNonDeletedElements } from "../element";
import { isImageFileHandle } from "../data/blob";
import { nativeFileSystemSupported } from "../data/filesystem";
import { Theme } from "../element/types";
import axios from "axios";
import { loadEnv } from "vite";
import "../components/ToolIcon.scss";

export const actionChangeProjectName = register({
	name: "changeProjectName",
	trackEvent: false,
	perform: (_elements, appState, value) => {
		return { appState: { ...appState, name: value }, commitToHistory: false };
	},
	PanelComponent: ({ appState, updateData, appProps, data }) => (
		<ProjectName
			label={t("labels.fileTitle")}
			value={appState.name || "Unnamed"}
			onChange={(name: string) => updateData(name)}
			isNameEditable={
				typeof appProps.name === "undefined" && !appState.viewModeEnabled
			}
			ignoreFocus={data?.ignoreFocus ?? false}
		/>
	),
});

export const actionChangeExportScale = register({
	name: "changeExportScale",
	trackEvent: { category: "export", action: "scale" },
	perform: (_elements, appState, value) => {
		return {
			appState: { ...appState, exportScale: value },
			commitToHistory: false,
		};
	},
	PanelComponent: ({ elements: allElements, appState, updateData }) => {
		const elements = getNonDeletedElements(allElements);
		const exportSelected = isSomeElementSelected(elements, appState);
		const exportedElements = exportSelected
			? getSelectedElements(elements, appState)
			: elements;

		return (
			<>
				{EXPORT_SCALES.map((s) => {
					const [width, height] = getExportSize(
						exportedElements,
						DEFAULT_EXPORT_PADDING,
						s,
					);

					const scaleButtonTitle = `${t(
						"imageExportDialog.label.scale",
					)} ${s}x (${width}x${height})`;

					return (
						<ToolButton
							key={s}
							size="small"
							type="radio"
							icon={`${s}x`}
							name="export-canvas-scale"
							title={scaleButtonTitle}
							aria-label={scaleButtonTitle}
							id="export-canvas-scale"
							checked={s === appState.exportScale}
							onChange={() => updateData(s)}
						/>
					);
				})}
			</>
		);
	},
});

export const actionChangeExportBackground = register({
	name: "changeExportBackground",
	trackEvent: { category: "export", action: "toggleBackground" },
	perform: (_elements, appState, value) => {
		return {
			appState: { ...appState, exportBackground: value },
			commitToHistory: false,
		};
	},
	PanelComponent: ({ appState, updateData }) => (
		<CheckboxItem
			checked={appState.exportBackground}
			onChange={(checked) => updateData(checked)}
		>
			{t("imageExportDialog.label.withBackground")}
		</CheckboxItem>
	),
});

export const actionChangeExportEmbedScene = register({
	name: "changeExportEmbedScene",
	trackEvent: { category: "export", action: "embedScene" },
	perform: (_elements, appState, value) => {
		return {
			appState: { ...appState, exportEmbedScene: value },
			commitToHistory: false,
		};
	},
	PanelComponent: ({ appState, updateData }) => (
		<CheckboxItem
			checked={appState.exportEmbedScene}
			onChange={(checked) => updateData(checked)}
		>
			{t("imageExportDialog.label.embedScene")}
			<Tooltip label={t("imageExportDialog.tooltip.embedScene")} long={true}>
				<div className="excalidraw-tooltip-icon">{questionCircle}</div>
			</Tooltip>
		</CheckboxItem>
	),
});

export const actionSaveToActiveFile = register({
	name: "saveToActiveFile",
	trackEvent: { category: "export" },
	predicate: (elements, appState, props, app) => {
		return (
			!!app.props.UIOptions.canvasActions.saveToActiveFile &&
			!!appState.fileHandle &&
			!appState.viewModeEnabled
		);
	},
	perform: async (elements, appState, value, app) => {
		const fileHandleExists = !!appState.fileHandle;

		try {
			const { fileHandle } = isImageFileHandle(appState.fileHandle)
				? await resaveAsImageWithScene(elements, appState, app.files)
				: await saveAsJSON(elements, appState, app.files);

			return {
				commitToHistory: false,
				appState: {
					...appState,
					fileHandle,
					toast: fileHandleExists
						? {
							message: fileHandle?.name
								? t("toast.fileSavedToFilename").replace(
									"{filename}",
									`"${fileHandle.name}"`,
								)
								: t("toast.fileSaved"),
						}
						: null,
				},
			};
		} catch (error: any) {
			if (error?.name !== "AbortError") {
				console.error(error);
			} else {
				console.warn(error);
			}
			return { commitToHistory: false };
		}
	},
	keyTest: (event) =>
		event.key === KEYS.S && event[KEYS.CTRL_OR_CMD] && !event.shiftKey,
});

export const actionSaveFileToDisk = register({
	name: "saveFileToDisk",
	viewMode: true,
	trackEvent: false,
	// trackEvent: { category: "export" },
	// perform: async (elements, appState, value, app) => {
	//   console.log("saving file to disk")
	//   try {
	//     const { fileHandle } = await saveAsJSON(
	//       elements,
	//       {
	//         ...appState,
	//         fileHandle: null,
	//       },
	//       app.files,
	//     );
	//     console.log(fileHandle)
	//     return {
	//       commitToHistory: false,
	//       appState: {
	//         ...appState,
	//         openDialog: null,
	//         fileHandle,
	//         toast: { message: t("toast.fileSaved") },
	//       },
	//     };
	//   } catch (error: any) {
	//     if (error?.name !== "AbortError") {
	//       console.error(error);
	//     } else {
	//       console.warn(error);
	//     }
	//     return { commitToHistory: false };
	//   }
	// },
	perform: async (elements, appState, value, app) => {
		console.log("sending data to server", value, app, appState)
		try {
			const { fileHandle } = await saveAsJSON(elements,
				{
					...appState,
					fileHandle: null,
				},
				app.files,
			);
			const reader = new FileReader();
			// Read the file as a Blob
			//@ts-ignore
			reader.readAsArrayBuffer(await fileHandle?.getFile());
			// Wait for the read operation to finish
			await new Promise(resolve => reader.onloadend = resolve);
			console.log("dsvhjgsfvfd")
			const formData = new FormData();
			let fileName = "Untitled"
			if (appState?.userDataForExport?.fileName != null || appState?.userDataForExport?.fileName != undefined) {
				fileName = appState?.userDataForExport?.fileName
			}
			formData.append('title', fileName);
			let description = ""
			if (appState?.userDataForExport?.description != null || appState?.userDataForExport?.description != undefined) {
				description = appState?.userDataForExport?.description
			}
			formData.append('description', description);
			let chapterId = ""
			if (appState?.userDataForExport?.chapterId != null || appState?.userDataForExport?.chapterId != undefined) {
				chapterId = appState?.userDataForExport?.chapterId
			}
			formData.append('chapterId', chapterId);
			//@ts-ignore
			formData.append('files', new Blob([reader.result]), fileHandle.name);
			// await axios.post(import.meta.env.VITE_APP_BACKEND_V2_GET_URL, formData, {
			// 	headers: {
			// 		Authorization: 'Bearer ' + localStorage.getItem('token')
			// 	}
			// });
			return {
				commitToHistory: false,
				appState: {
					...appState,
					openDialog: null,
					toast: { message: t("toast.fileSaved") },
				},
			};
		} catch (error: any) {
			if (error?.name !== "AbortError") {
				console.error(error);
			} else {
				console.warn(error);
			}
			return { commitToHistory: false };
		}
	},
	// keyTest: (event) =>
	//   event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
	// PanelComponent: ({ updateData }) => (
	//   <ToolButton
	//     type="button"
	//     icon={saveAs}
	//     title={t("buttons.saveAs")}
	//     aria-label={t("buttons.saveAs")}
	//     showAriaLabel={useDevice().editor.isMobile}
	//     hidden={!nativeFileSystemSupported}
	//     onClick={() => updateData(null)}
	//     data-testid="save-as-button"
	//   />
	// ),
});
export const actionSaveFileToYIES = register({
	name: "saveFileToYIES",
	viewMode: true,
	trackEvent: { category: "export" },
	perform: async (elements, appState, value, app) => {
		const openRequest = indexedDB.open('files-db');
		//@ts-ignore
		openRequest.onsuccess = async function () {
			const db = openRequest.result;
			const transaction = db.transaction('files-store');
			const store = transaction.objectStore('files-store');

			store.getAll().onsuccess = async function (event) {
				//@ts-ignore
				const filesArray = event.target.result;
				let excaliFile = JSON.parse(localStorage.getItem('excalidraw') || "{}");
				excaliFile["appState"] = JSON.parse(localStorage.getItem('excalidraw-state') || "{}")
				const excaliFileAsJson = JSON.stringify(excaliFile);
				const formData = new FormData();
				let fileName = "Untitled"
				console.log(appState?.userDataForExport?.fileName)
				if (appState?.userDataForExport?.fileName != null && appState?.userDataForExport?.fileName != undefined) {
					fileName = appState?.userDataForExport?.fileName
					console.log(fileName)
				}
				formData.append('title', fileName);
				let description = ""
				if (appState?.userDataForExport?.description != null || appState?.userDataForExport?.description != undefined) {
					description = appState?.userDataForExport?.description
				}
				formData.append('description', description);
				let chapterId = ""
				if (appState?.userDataForExport?.chapterId != null || appState?.userDataForExport?.chapterId != undefined) {
					chapterId = appState?.userDataForExport?.chapterId
				}
				formData.append('chapterID', chapterId);
				formData.append('metaData', excaliFileAsJson);
				for (const file of filesArray) {
					formData.append('files', JSON.stringify(file));
				}
				return await axios.post(import.meta.env.VITE_APP_BACKEND_V2_GET_URL, formData, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				}).then((response) => {
					value("exportSucess");
					localStorage.setItem("backendWhiteboardId", response.data?._id)
					return {
						commitToHistory: false,
						appState: {
							...appState,
							openDialog: null,
							toast: { message: t("toast.fileSaved") },
						},
					};
				}).catch((error) => {
					value("exportError");
					return {
						commitToHistory: false,
						appState: {
							...appState,
							toast: { message: "Error" },
						},
					};
				});
			};
		};
		return { commitToHistory: false };
		// console.log("sending data to server", value, app, appState)
		// try {
		// 	const { fileHandle } = await saveAsJSON(elements,
		// 		{
		// 			...appState,
		// 			fileHandle: null,
		// 		},
		// 		app.files,
		// 	);
		// 	const reader = new FileReader();
		// 	// Read the file as a Blob
		// 	//@ts-ignore
		// 	reader.readAsArrayBuffer(await fileHandle?.getFile());
		// 	// Wait for the read operation to finish
		// 	await new Promise(resolve => reader.onloadend = resolve);
		// 	console.log("dsvhjgsfvfd")

		// 	return {
		// 		commitToHistory: false,
		// 		appState: {
		// 			...appState,
		// 			openDialog: null,
		// 			toast: { message: t("toast.fileSaved") },
		// 		},
		// 	};
		// } catch (error: any) {
		// 	if (error?.name !== "AbortError") {
		// 		console.error(error);
		// 	} else {
		// 		console.warn(error);
		// 	}
		// 	return { commitToHistory: false };
		// }
	},

});

export const actionLoadScene = register({
	name: "loadScene",
	trackEvent: { category: "export" },
	predicate: (elements, appState, props, app) => {
		return (
			!!app.props.UIOptions.canvasActions.loadScene && !appState.viewModeEnabled
		);
	},
	perform: async (elements, appState, _, app) => {
		console.log("Loading scene")
		// try {
		//   const {
		//     elements: loadedElements,
		//     appState: loadedAppState,
		//     files,
		//   } = await loadFromJSON(appState, elements);
		//   return {
		//     elements: loadedElements,
		//     appState: loadedAppState,
		//     files,
		//     commitToHistory: true,
		//   };
		// } catch (error: any) {
		//   if (error?.name === "AbortError") {
		//     console.warn(error);
		//     return false;
		//   }
		//   return {
		//     elements,
		//     appState: { ...appState, errorMessage: error.message },
		//     files: app.files,
		//     commitToHistory: false,
		//   };
		// }
		try {
			console.log(localStorage.getItem("backendWhiteboardId"))
			const response = await axios.get(import.meta.env.VITE_APP_BACKEND_V2_GET_URL, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token')
				},
				params: {
					id: localStorage.getItem("backendWhiteboardId")
				}
			});
			console.log(response.data)
			const { fileLink } = response.data[0];

			const fileResponse = await axios.get(fileLink, {
				responseType: 'json'
			})
			console.log(fileResponse.data)

			const { elements, appState, files } = fileResponse.data

			return {
				elements: elements,
				appState: appState,
				files,
				commitToHistory: true,
			};
		} catch (error: any) {
			if (error?.name === "AbortError") {
				console.warn(error);
				return false;
			}
			return {
				elements,
				appState: {
					...appState, errorMessage: error.message
				},
				files: app.files,
				commitToHistory: false,
			};
		}
	},
	keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
});

export const actionExportWithDarkMode = register({
	name: "exportWithDarkMode",
	trackEvent: { category: "export", action: "toggleTheme" },
	perform: (_elements, appState, value) => {
		return {
			appState: { ...appState, exportWithDarkMode: value },
			commitToHistory: false,
		};
	},
	PanelComponent: ({ appState, updateData }) => (
		<div
			style={{
				display: "flex",
				justifyContent: "flex-end",
				marginTop: "-45px",
				marginBottom: "10px",
			}}
		>
			<DarkModeToggle
				value={appState.exportWithDarkMode ? THEME.DARK : THEME.LIGHT}
				onChange={(theme: Theme) => {
					updateData(theme === THEME.DARK);
				}}
				title={t("imageExportDialog.label.darkMode")}
			/>
		</div>
	),
});
