import React from "react";
import { Card } from "../../src/components/Card";
import { ToolButton } from "../../src/components/ToolButton";
import { serializeAsJSON } from "../../src/data/json";
import { loadFirebaseStorage, saveFilesToFirebase } from "../data/firebase";
import { FileId, NonDeletedExcalidrawElement } from "../../src/element/types";
import { AppState, BinaryFileData, BinaryFiles, ExportOpts, UIAppState } from "../../src/types";
import { nanoid } from "nanoid";
import { useI18n } from "../../src/i18n";
import { encryptData, generateEncryptionKey } from "../../src/data/encryption";
import { isInitializedImageElement } from "../../src/element/typeChecks";
import { FILE_UPLOAD_MAX_BYTES } from "../app_constants";
import { encodeFilesForUpload } from "../data/FileManager";
import { MIME_TYPES } from "../../src/constants";
import { trackEvent } from "../../src/analytics";
import { getFrame } from "../../src/utils";
import { ExcalidrawLogo } from "../../src/components/ExcalidrawLogo";
import axios, { AxiosResponse } from 'axios';

//for dialog box

import { t } from "../../src/i18n";
import { useState, useEffect } from "react";
import { Dialog } from '../../src/components/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { use } from "chai";
import { set } from "idb-keyval";

import "../../src/components/ExportDialog.scss";
import { ActionManager } from "../../src/actions/manager";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { s } from "vitest/dist/types-3c7dbfa5";
import FormHelperText from "@material-ui/core/FormHelperText";
import { actionSaveFileToDisk, actionSaveFileToYIES } from "../../src/actions/actionExport";

// export const exportToExcalidrawPlus = async (
// 	elements: readonly NonDeletedExcalidrawElement[],
// 	appState: Partial<AppState>,
// 	files: BinaryFiles,
// ) => {
// 	// const firebase = await loadFirebaseStorage();

// 	const id = `${nanoid(12)}`;

// 	const encryptionKey = (await generateEncryptionKey())!;
// 	const encryptedData = await encryptData(
// 		encryptionKey,
// 		serializeAsJSON(elements, appState, files, "database"),
// 	);

// 	const blob = new Blob(
// 		[encryptedData.iv, new Uint8Array(encryptedData.encryptedBuffer)],
// 		{
// 			type: MIME_TYPES.binary,
// 		},
// 	);

// 	console.log("lalalal", blob)




// 	console.log("lalalalalalal", import.meta.env.VITE_APP_BASE_BACKEND_URL)
// 	console.log("lalalalalalal", import.meta.env.VITE_APP_BACKEND_V2_GET_URL)
// 	// await axios.get("http://localhost:3000/api/v1/school-entity/school").then((res) => {
// 	const [schoolList, setSchoolList] = useState([]);
// 	const [subjectList, setSubjectList] = useState([]);

// 	await axios.get(`${import.meta.env.VITE_APP_BASE_BACKEND_URL}school-entity/school`, {
// 		headers: {
// 			Authorization: 'Bearer ' + localStorage.getItem('token')
// 		}
// 	}).then((res) => {
// 		console.log(res.data);
// 		res.data.sort((a, b) => a.name.localeCompare(b.name));
// 		res.data = [{ _id: "", name: "All" }, ...res.data];
// 		setSchoolList(res.data);
// 	});

// 	await axios.get(`${import.meta.env.VITE_APP_BASE_BACKEND_URL}preconfig-entities/subjects`, {
// 		headers: {
// 			Authorization: 'Bearer ' + localStorage.getItem('token')
// 		}
// 	}).then((res) => {
// 		console.log(res.data);
// 		res.data.sort((a, b) => a.name.localeCompare(b.name));
// 		setSubjectList(res.data);
// 	});


// 	// await firebase
// 	//   .storage()
// 	//   .ref(`/migrations/scenes/${id}`)
// 	//   .put(blob, {
// 	//     customMetadata: {
// 	//       data: JSON.stringify({ version: 2, name: appState.name }),
// 	//       created: Date.now().toString(),
// 	//     },
// 	//   });

// 	// const formData = new FormData();
// 	// formData.append('title', 'test1234');
// 	// formData.append('districtID', 'bb334635-6c42-45ec-a6eb-7106477b6189');
// 	// formData.append('encryptionKey', encryptionKey);
// 	// formData.append('metaData', excaliFileAsJson);

// 	const openRequest = indexedDB.open('files-db');
// 	//@ts-ignore
// 	openRequest.onsuccess = async function () {
// 		const db = openRequest.result;
// 		const transaction = db.transaction('files-store');
// 		const store = transaction.objectStore('files-store');

// 		store.getAll().onsuccess = async function (event) {
// 			//@ts-ignore
// 			const filesArray = event.target.result;
// 			let excaliFile = JSON.parse(localStorage.getItem('excalidraw') || "{}");
// 			excaliFile["type"] = "excalidraw";
// 			excaliFile["version"] = 2;
// 			excaliFile["source"] = "http://localhost:3001";
// 			excaliFile["appState"] = JSON.parse(localStorage.getItem('excalidraw-state') || "{}")
// 			const excaliFileAsJson = JSON.stringify(excaliFile);
// 			console.log(excaliFile)
// 			const formData = new FormData();
// 			formData.append('title', 'test1234');
// 			formData.append('districtID', 'bb334635-6c42-45ec-a6eb-7106477b6189');
// 			formData.append('encryptionKey', encryptionKey);
// 			formData.append('metaData', excaliFileAsJson);

// 			// Append each file to the FormData
// 			for (const file of filesArray) {
// 				formData.append('files', JSON.stringify(file));
// 			}

// 			await axios.post(import.meta.env.VITE_APP_BACKEND_V2_GET_URL, formData, {
// 				headers: {
// 					Authorization: 'Bearer ' + localStorage.getItem('token')
// 				}
// 			});
// 		};
// 	};

// 	//@ts-ignore

// 	const filesMap = new Map<FileId, BinaryFileData>();
// 	for (const element of elements) {
// 		if (isInitializedImageElement(element) && files[element.fileId]) {
// 			filesMap.set(element.fileId, files[element.fileId]);
// 		}
// 	}

// 	console.log(filesMap)



// 	// useEffect(() => {
// 	//     if (filterSchoolId) {
// 	//         axios.get("school-entity/all-classes?school_id=" + filterSchoolId).then((res) => {
// 	//             setClassList(res.data);
// 	//             setFilterUserClasses([]);
// 	//         })
// 	//     } 
// 	//     else if (userDetails?.schoolId) {

// 	//         axios.get("school-entity/all-classes?school_id=" + userDetails?.schoolId).then((res) => {
// 	//             setClassList(res.data);
// 	//             setFilterUserClasses([]);
// 	//         })
// 	//     } 
// 	//     else {
// 	//         setClassList([]);
// 	//         setFilterUserClasses([]);
// 	//     }
// 	// }, [userDetails, filterSchoolId]);




// 	// if (filesMap.size) {
// 	//   const filesToUpload = await encodeFilesForUpload({
// 	//     files: filesMap,
// 	//     encryptionKey,
// 	//     maxBytes: FILE_UPLOAD_MAX_BYTES,
// 	//   });

// 	//   await axios.post('http://localhost:3000/api/v1/whiteboard',{
// 	//     title: 'test',
// 	//     files: [filesToUpload],
// 	//     encryptionKey: encryptionKey,
// 	//     districtID: 'bb334635-6c42-45ec-a6eb-7106477b6189',
// 	//   },{
// 	//     headers:{
// 	//       'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJlODJlZTQ5OC0wZGFmLTQ1YzgtOWY0OS1jMmEzNzczNmQxMGIiLCJpYXQiOjE2OTQ3MTQ4NzksImV4cCI6MTcwMzM1NDg3OSwiYXVkIjoiaHR0cDovL2NobmlydC5naXRodWIuaW8iLCJpc3MiOiJDaG5pcnQgY29ycCIsInN1YiI6IntcImVtYWlsXCI6XCIyMDA1Nzg4QGtpaXQuYWMuaW5cIixcInBhc3N3b3JkXCI6XCIkMmIkMTAkbEhUWFp4cG4ueFIyS0s2MFppbDZ5ZUYyeS9PSkVQa0NvWWtsczNmZVZqQW1Bd1lIQk9ORHlcIn0ifQ.waDDkujha5J7KV_i7y7f8bRGxKlJWF9JmZaqVqIptmI',
// 	//       'Content-Type':'application/json'
// 	//     }
// 	//   })

// 	//   // await saveFilesToFirebase({
// 	//   //   prefix: `/migrations/files/scenes/${id}`,
// 	//   //   files: filesToUpload,
// 	//   // });
// 	// }
// 	console.log("Skipped saving files to Eschool")
// 	// window.open(
// 	//   `${
// 	//     import.meta.env.VITE_APP_PLUS_APP
// 	//   }/import?excalidraw=${id},${encryptionKey}`,
// 	// );
// };



export const ExportToExcalidrawPlus: React.FC<{
	elements: readonly NonDeletedExcalidrawElement[];
	appState: Partial<AppState>;
	files: BinaryFiles;
	onError: (error: Error) => void;
	onSuccess: (value?: AppState["openDialog"]) => void;
}> = ({ elements, appState, files, onError, onSuccess }) => {
	const { t } = useI18n();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	return (
		<Card color="primary">
			<div className="Card-icon">
				<ExcalidrawLogo
					style={{
						[`--color-logo-icon` as any]: "#fff",
						width: "2.8rem",
						height: "2.8rem",
					}}
				/>
			</div>
			<h2>Youth India E-School LMS</h2>
			<div className="Card-details">
				{t("exportDialog.excalidrawplus_description")}
			</div>
			<ToolButton
				className="Card-button"
				type="button"
				title={t("exportDialog.excalidrawplus_button")}
				aria-label={t("exportDialog.excalidrawplus_button")}
				showAriaLabel={true}
				onClick={async () => {
					try {
						if (localStorage.getItem("backendWhiteboardId")) {
							const openRequest = indexedDB.open('files-db');
							//@ts-ignore
							openRequest.onsuccess = async function () {
								const db = openRequest.result;
								const transaction = db.transaction('files-store');
								const store = transaction.objectStore('files-store');

								store.getAll().onsuccess = async function (event) {
									//@ts-ignore
									const filesArray = event.target.result;
									let excaliFile = JSON.parse(localStorage.getItem('excalidraw') || "{}");
									excaliFile["appState"] = JSON.parse(localStorage.getItem('excalidraw-state') || "{}")
									const excaliFileAsJson = JSON.stringify(excaliFile);
									const formData = new FormData();
									let id = localStorage.getItem("backendWhiteboardId")
									formData.append('metaData', excaliFileAsJson);
									for (const file of filesArray) {
										formData.append('files', JSON.stringify(file));
									}
									await axios.put(import.meta.env.VITE_APP_BACKEND_V2_GET_URL + "?id=" + id, formData, {
										headers: {
											Authorization: 'Bearer ' + localStorage.getItem('token')
										}
									}).then((response) => {
										onSuccess("exportSucess");
										localStorage.setItem("backendWhiteboardId", response.data?._id)
									}).catch((error) => {
										onSuccess("exportError");
									});
								}
							}
						} else {
							onSuccess("jsonExportYIES");
						}
					} catch (error: any) {
						console.error(error);
						if (error.name !== "AbortError") {
							onError(new Error(t("exportDialog.excalidrawplus_exportError")));
						}
					}
				}}
			/>
		</Card>
	);
};


export const DialogBox: React.FC<{
	elements: readonly NonDeletedExcalidrawElement[];
	appState: UIAppState;
	files: BinaryFiles;
	actionManager: ActionManager;
	exportOpts: ExportOpts;
	canvas: HTMLCanvasElement;
	setAppState: React.Component<any, UIAppState>["setState"];
	onCloseRequest: () => void;
}> = ({ elements,
	appState,
	files,
	actionManager,
	exportOpts,
	canvas,
	setAppState,
	onCloseRequest
}) => {

		const [courseListByYouthIndia, setCourseListByYouthIndia] = useState([]);
		const [courseListBySchool, setCourseListBySchool] = useState([]);
		const [schoolList, setSchoolList] = useState([]);
		const [classList, setClassList] = useState([]);
		const [subjectList, setSubjectList] = useState([]);
		const [filterUserClasses, setFilterUserClasses] = useState([] as any);
		const [filterUserSubjects, setFilterUserSubjects] = useState([]);
		const [filterSchoolId, setFilterSchoolId] = useState("");
		const [filterCourseName, setFilterCourseName] = useState("");
		const [userSchoolId, setUserSchoolId] = useState("");
		const header = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token')
			}
		};
		const [title, setTitle] = useState("");
		const [description, setDescription] = useState("");
		const [showError, setShowError] = useState({
			title: false,
			description: false,
			school: false,
			class: false,
			subject: false,
			course: false,
			chapter: false,
		});
		const [canErrorShow, setCanErrorShow] = useState({
			title: false,
			description: false,
			school: false,
			class: false,
			subject: false,
			course: false,
			chapter: false,
		});
		useEffect(() => {
			axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "school-entity/school", header).then((res: AxiosResponse) => {
				if (Array.isArray(res.data) && res.data.length > 0) {
					res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
					res.data = [{ _id: "", name: "All" }, ...res.data];
					setSchoolList(res.data);
					setUserSchoolId("");
				} else {
					setUserSchoolId(res.data._id);
				}
			})
			axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "preconfig-entities/subjects", header).then((res) => {
				res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
				setSubjectList(res.data);
			})
		}, [localStorage.getItem("token")]);
		useEffect(() => {
			if (filterSchoolId) {
				axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "school-entity/all-classes?school_id=" + filterSchoolId, header).then((res) => {
					setClassList(res.data?.map((classObj: any) => {
						return {
							"_id": classObj?._id,
							"class_name": classObj?.class_name,
							"class_name_section": classObj?.class_name_section,
						}
					}));
					setFilterUserClasses([]);
				})
			} else if (userSchoolId) {
				axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "school-entity/all-classes?school_id=" + userSchoolId, header).then((res) => {
					setClassList(res.data?.map((classObj: any) => {
						return {
							"_id": classObj?._id,
							"class_name": classObj?.class_name,
							"class_name_section": classObj?.class_name_section,
						}
					}));
					setFilterUserClasses([]);
				})
			} else {
				setClassList([]);
				setFilterUserClasses([]);
			}
		}, [localStorage.getItem("token"), filterSchoolId]);
		useEffect(() => {
			axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "course/get-yie-courses", header).then((res) => {
				setCourseListByYouthIndia(res.data?.map((course: any) => {
					return {
						"_id": course?._id,
						"subject_name": course?.subject_name,
						"subject_id": course?.subject_id,
						"class_name": course?.class_name,
						"class_id": course?.class_id,
					}
				}))
			}).finally(() => {
				axios.get(import.meta.env.VITE_APP_BASE_BACKEND_URL + "course/get-inhouse-courses", header).then((res) => {
					setCourseListBySchool(res.data?.map((course: any) => {
						return {
							"_id": course?._id,
							"subject_name": course?.subject_name,
							"subject_id": course?.subject_id,
							"class_name": course?.class_name,
							"class_id": course?.class_id,
						}
					}));
				})
			})
		}, [localStorage.getItem("token")]);
		const [filteredCourseListByYouthIndia, setFilteredCourseListByYouthIndia] = useState([]);
		const [filteredCourseListBySchool, setFilteredCourseListBySchool] = useState([]);
		const [filterCourseList, setFilterCourseList] = useState([]);
		const [filterCourse, setFilterCourse] = useState({} as any);
		const [filterCourseChapters, setFilterCourseChapters] = useState([]);
		const [filterChapter, setFilterChapter] = useState({} as any);
		useEffect(() => {
			var filteredCourseListByYouthIndia = courseListByYouthIndia ? courseListByYouthIndia : [];
			var filteredCourseListBySchool = courseListBySchool ? courseListBySchool : [];
			if (filterSchoolId) {
				filteredCourseListBySchool = filteredCourseListBySchool?.filter((course: any) => course.school_id === filterSchoolId);
			}
			if (filterUserClasses?.length !== 0) {
				filteredCourseListBySchool = filteredCourseListBySchool?.filter((course: any) => filterUserClasses?.some((classObj: any) => classObj?._id === course?.class_id));
				filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course: any) => (filterUserClasses?.some((classObj: any) => classObj?.class_name === course?.class_name) || (course?.class_name === "GLOBAL")));
			}
			if (filterUserSubjects?.length !== 0) {
				filteredCourseListBySchool = filteredCourseListBySchool?.filter((course: any) => filterUserSubjects?.some((subject: any) => subject?._id === course?.subject_id));
				filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course: any) => filterUserSubjects?.some((subject: any) => subject?._id === course?.subject_id));
			}
			if (filterCourseName) {
				filteredCourseListBySchool = filteredCourseListBySchool?.filter((course: any) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
				filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course: any) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
			}
			setFilteredCourseListByYouthIndia(filteredCourseListByYouthIndia);
			setFilteredCourseListBySchool(filteredCourseListBySchool);
			setFilterCourseList(
				[...filteredCourseListByYouthIndia, ...filteredCourseListBySchool]
			);
		}, [filterSchoolId, filterUserClasses, filterUserSubjects, filterCourseName, courseListByYouthIndia, courseListBySchool]);

		useEffect(() => {
			if (Object.keys(filterCourse).length !== 0) {
				axios.post(import.meta.env.VITE_APP_BASE_BACKEND_URL + "course/get-chapters", { course_id: filterCourse?._id }, header).then((res) => {
					setFilterCourseChapters(res.data?.map((chapter: any) => {
						return {
							"_id": chapter?._id,
							"chapter_name": chapter?.chapter_name,
						}
					}))
				}).catch((err) => {
					setFilterCourseChapters([]);
				})
			} else {
				setFilterCourseChapters([]);
			}
		}, [filterCourse]);

		return (
			<Dialog onCloseRequest={onCloseRequest} title={t("exportDialog.save_to_lms")}>
				{userSchoolId ? <></> :
					<FormControl
						style={{ minWidth: "100%", paddingBottom: "1.5vw" }}
					>
						<InputLabel id="school-select-helper-label">School</InputLabel>
						<Select
							value={filterSchoolId}
							onChange={(e) => {
								setFilterSchoolId(e.target.value as string);
								setFilterCourse({} as any);
							}}
							style={{ minWidth: "100%", marginBottom: "10px" }}
							label="Select School"
							labelId="school-select-helper-label"
						>
							{schoolList?.map((school: any) => (
								<MenuItem key={school._id} value={school._id}>{school.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				}
				{classList?.length !== 0 ?
					<FormControl
						style={{ minWidth: "100%", paddingBottom: "1.5vw" }}
					>
						<InputLabel id="class-select-helper-label">Class</InputLabel>
						<Select
							// @ts-ignore
							value={filterUserClasses?.length !== 0 ? filterUserClasses[0] : []}
							onChange={(e) => {
								setFilterUserClasses([e.target.value as any] as any);
								setFilterCourse({} as any);
								console.log(e.target.value, filterUserClasses);
							}}
							style={{ minWidth: "100%", marginBottom: "10px" }}
							label="Select School"
							labelId="school-select-helper-label"
						>
							{classList?.map((classroom: any) => (
								<MenuItem key={classroom._id} value={classroom}>{classroom.class_name_section}</MenuItem>
							))}
						</Select>
					</FormControl>
					: <></>
				}
				<FormControl
					style={{ minWidth: "100%", paddingBottom: "1.5vw" }}
					error={showError?.course}
					required
				>
					<InputLabel id="course-select-helper-label">Course</InputLabel>
					<Select
						// @ts-ignore
						value={Object.keys(filterCourse).length !== 0 ? filterCourse : ""}
						onChange={(e) => {
							setFilterCourse(e.target.value as any);
							setCanErrorShow({ ...canErrorShow, course: true });
							if (Object.keys(e.target.value as any).length > 0) {
								setShowError({ ...showError, course: false });
							} else {
								setShowError({ ...showError, course: true });
							}
						}}
						style={{ minWidth: "100%", marginBottom: "10px" }}
						label="Select Course"
						labelId="course-select-helper-label"
					>
						{filterCourseList?.map((course: any) => (
							<MenuItem key={course._id} value={course}>{course.subject_name}</MenuItem>
						))}
					</Select>
					<FormHelperText>{showError?.course ? "Course is required" : ""}</FormHelperText>
				</FormControl>
				{filterCourseChapters?.length !== 0 ?
					<FormControl
						style={{ minWidth: "100%", paddingBottom: "1.5vw" }}
						error={showError?.chapter}
						required
					>
						<InputLabel id="chapter-select-helper-label">Chapter</InputLabel>
						<Select
							// @ts-ignore
							value={Object.keys(filterChapter).length !== 0 ? filterChapter : ""}
							onChange={(e) => {
								setFilterChapter(e.target.value as any);
								setCanErrorShow({ ...canErrorShow, chapter: true });
								if (Object.keys(e.target.value as any).length > 0) {
									setShowError({ ...showError, chapter: false });
								} else {
									setShowError({ ...showError, chapter: true });
								}
							}}
							style={{ minWidth: "100%", marginBottom: "10px" }}
							label="Select Chapter"
							labelId="chapter-select-helper-label"
						>
							{filterCourseChapters?.map((chapter: any) => (
								<MenuItem key={chapter._id} value={chapter}>{chapter.chapter_name}</MenuItem>
							))}
						</Select>
						<FormHelperText>{showError?.chapter ? "Chapter is required" : ""}</FormHelperText>
					</FormControl>
					: <></>
				}
				<TextField
					fullWidth
					label="Title"
					variant="standard"
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
						setCanErrorShow({ ...canErrorShow, title: true });
						if (e.target.value.length > 0) {
							setShowError({ ...showError, title: false });
						} else {
							setShowError({ ...showError, title: true });
						}
					}}
					error={showError?.title}
					helperText={showError?.title ? "Title is required" : ""}
					required
					style={{ paddingBottom: "1.5vw" }}
				/>
				<TextField
					multiline
					fullWidth
					label="Description"
					variant="standard"
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
						// setCanErrorShow({ ...canErrorShow, description: true });
						// if (e.target.value.length > 0) {
						// 	setShowError({ ...showError, description: false });
						// } else {
						// 	setShowError({ ...showError, description: true });
						// }
					}}
					minRows={3}
					// error={showError?.description}
					// required
					style={{ paddingBottom: "1.5vw" }}
				/>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Button
						variant="contained"
						color="primary"
						onClick={async () => {
							if (title.length === 0) {
								setShowError({ ...showError, title: true });
								setCanErrorShow({ ...canErrorShow, title: true });
								return;
							}
							if (Object.keys(filterCourse).length === 0) {
								setShowError({ ...showError, course: true });
								setCanErrorShow({ ...canErrorShow, course: true });
								return;
							}
							if (Object.keys(filterChapter).length === 0) {
								setShowError({ ...showError, chapter: true });
								setCanErrorShow({ ...canErrorShow, chapter: true });
								return;
							}
							setShowError({ ...showError, title: false, chapter: false, course: false });
							setAppState({
								...appState,
								userDataForExport: {
									districtId: appState?.userDataForExport?.districtId,
									schoolId: filterSchoolId,
									classId: filterUserClasses?.length !== 0 ? filterUserClasses[0]._id as string : null,
									courseId: filterCourse?._id,
									chapterId: filterChapter?._id,
									fileName: title,
									description: description,
									id: appState?.userDataForExport?.id,
								}
							}, async () => {
								actionManager.executeAction(actionSaveFileToYIES, "api", onCloseRequest)
								// onCloseRequest();
							})

						}}
						style={{ width: "48%" }}
					>
						Export
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							onCloseRequest();
						}}
						style={{ width: "48%", boxShadow: "none" }}
					>
						Cancel
					</Button>
				</div>
			</Dialog >
		);
	};
